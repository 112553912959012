import { reducer as page } from './page'
import { reducer as paper } from '../views/config/paper/reducer'
import { reducer as host } from '../views/host/reducer'
import { reducer as guest } from '../views/guest/reducer'
import { reducer as sepectator } from '../views/spectator/reducer'
export const reducers = {
  page: page,
  paper: paper,
  host: host,
  guest: guest,
  sepectator: sepectator,
}
