/// 在这里配置接口请求地址，在store中使用

// 保存缓存信息,主要是一些id,方便掉线后使用
export const postCacheDataByActivity = '/user/init/activity/cache'
export const getCacheDataByActivity = '/user/get/activity/cache'
export const postCacheData = '/user/init/cache'
// 获取缓存信息
export const postGetCacheData = '/user/get/cache'

// 根据id判断,试卷是否可以修改
export const postPaperOnActivity = '/activity/get/state/by/templateid'

// 保存试卷
export const postSavePaper = '/paper/save'
// 删除试卷
export const getDelPaper = '/paper/del'
// 修改该活动状态
export const postActivityState = '/activity/toState'
// 获取分页试卷列表/{size}/{page}
export const getPaperListJson = '/paper/all'
// 根据试卷ID查询试题接口
export const getPaperQuestion = '/paperQuestion/get/by/id'
// 保存用户
export const postUserSave = '/user/save'
// 获取活动信息
export const getActivityInfo = '/activity/get'
// 保存活动信息
export const postSaveActivityInfo = '/activity/save'

// 查询没有入队的成员
export const getActivityNoJoinInfo = '/activityMember/getNotJoinedMemberByActivityId'
// 活动成员
export const getActivityUsersInfo = '/activityMember/getMemberByActivityId'
// 删除团队成员
export const getDelUser = '/activityMember/delete'
// 批量增加团队
export const postPatchUserToTeam = '/activityMember/add/batch'
// 单个增加团队成员
export const postUserToTeam = '/activityMember/add'
// 查询试卷列表
export const getPaperList = '/activityPaperRelation/list'
// 查询入队申请
export const getApplyTeamMsg = '/activityMemberApplyLog/list'
// 处理入队申请
export const postApplyTeamMsg = '/activityMemberApplyLog/deal'
// 新增申请
export const getApplyEnterTeam = '/activityMemberApplyLog/add'

// 查询试卷中的试题 主持端
export const getPaperQuestionList = '/activityPaperRelation/questionAndState'
// 查询试卷中的试题，选手端
export const getPaperQuestionListByGuest = '/activityPaperRelation/question'
// 进入抢答
export const getPaperEnter = '/activityPaperRelation/enterPaper'
// 选择试题进入抢答
export const getAnswerEnter = '/activityPaperRelation/startAnswer'
// 获取抢答前5ming
export const getAnswerTop5 = '/answerHandLog/get/top'
// 根据人员判断加入那个团队
export const getUserTeam = '/activityMember/getGroupByUserId'
// 判断人员是否已经申请了
export const getUserApplyTeam = '/activityMemberApplyLog/getApplyGroupState'
// 提交抢答
export const postSubmitRush = '/answerHandLog/save'
// 修改该试题状态
export const postQuestionStateChange = '/activityPaperQuestionState/to/state'
// 判别对错
export const postQuestionIsRight = '/answerHandLog/judge'
// 活动结束
export const getFinishActivity = '/activity/tofinish'

// 查询比赛排名
export const postGetSortList = '/scoreLog/score/paper'
// 查询比赛最好选手
export const postGetBestPerson = '/scoreLog/score/best'
// 公布比赛结果
export const getRecordList = '/activity/topublic'
// 选手列表团队
export const getTeamUserList = '/scoreLog/paper/group/all/people'
// 选手个人模式
export const getUserModalList = '/scoreLog/paper/personal/all/people'
// 查看历史列表
export const getHistoryList = '/activityPaperRelation/history/list'
// 查看历史详细记录
export const getHistoryDetail = '/activityPaperRelation/history/detail'
// 加分
export const getAddUserScore = '/scoreLog/extra'
// 添加个人基础分数
export const addUserBaseScore = '/scoreLog/basic/add'

// 判断是否已经发布
export const checkIfPublish = '/scoreLog/paper/result/pulish'
// 获取人员分数
export const getPersonTeamScore = '/activityPaperRelation/score'
// 获取此人是否已经抢答
// export const getPersonIsRush = '/answerHandLog/isHand'
// 邀请发言
export const getInviteSpeak = '/answerHandLog/invite'
// 结束发言
export const stopInviteSpeak = '/answerHandLog/finish/invite'
// 结束下线时
export const offlineUser = '/user/offline'
// 获取试题状态
export const getQuestionState = '/activityPaperQuestionState/get/state'
// 保存是否发布了最佳选手和比赛结果
export const checkPublishSortOrPlayer = '/scoreLog/score/public'
// 记录用户是否发言
export const saveUSerIsSpeaked = '/user/set/speak'
// 获取用户是否发言
export const checkUserIsSpeaking = '/user/get/speak'
// 保存发布最佳选手或列表的页面
export const savePublishSortOrPlayer = '/scoreLog/score/set/page/state'
// 保存主持人和联席主持人，保存页面地址信息
export const savePageAddressByHost = '/activity/cache/save'
export const getPageAddressByHost = '/activity/cache/get'
// 获取倒计时
export const getCountDownTime = '/activity/get/time'
export const delCountDownTime = '/activity/del/time'
export const saveCountDownTime = '/activity/save/time'
// 主持端进入时，更新下在线用户
export const updateOnlineUsers = '/user/update/online'
