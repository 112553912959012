import { RoleItem } from '../entity/config.interface'
import { IUserInfo } from '../entity/host.interface'
import { ILocalUserInfo, IqsyUserInfoObj } from '../entity/system.interface'
import { savePageAddressByHostThunk } from '../views/host/reducer'

export const dup = (key: string, data: Array<any>) => {
  let ret: any = undefined
  try {
    const map = new Map()
    data.forEach((d) => map.set(d[key], d))
    ret = Array.from(map.values())
    map.clear()
  } catch (e) {
    ret = data
  }
  return ret
}
export const log = (msg: { [key: string]: string }) => {
  let txt = ''
  for (const key in msg) {
    txt += `【${key}】: ${msg[key]}`
  }
  // eslint-disable-next-line no-console
  console.log(`qsy_waibao::`, txt)
}
let toastId: any = undefined
export const toastInfo = (msg: string) => {
  if (!window.toast) return
  if (!toastId) {
    toastId = window.toast.info(msg)
  } else if (window.toast.isActive(toastId)) {
    window.toast.update(toastId, {
      render: msg,
      type: window.toast.TYPE.INFO,
    })
  } else {
    window.toast.dismiss(toastId)
    setTimeout(() => {
      toastId = window.toast.info(msg)
    }, 1000)
  }
}
// 判断全时用户是否是同一个人
export const isSameQsyUser = (one: IqsyUserInfoObj, two: IqsyUserInfoObj | ILocalUserInfo | IUserInfo) => {
  return (
    one.tempUserId === ('tempUserId' in two ? two.tempUserId : two.tempuserid) ||
    (one.email && two.email && one.email === two.email) ||
    (one.extId && two.extId && one.extId === two.extId) ||
    (one.mobile && two.mobile && one.mobile === two.mobile)
  )
}
// 判断是否主持人
export const userIsHost = (btplConfRoles: RoleItem[] | undefined, currentUser: RoleItem | undefined) => {
  if (!btplConfRoles || !currentUser) return false
  const roleitem = btplConfRoles.filter((r) => r.managerType === 1) // 主持人
  if (roleitem.length > 0) {
    return roleitem.some((role) => role.roleId === currentUser.roleId) // currentUser.roleId === roleitem[0].roleId
  }
  return false
}
// 判断是否是联系主持人
export const userIsLinkHost = (btplConfRoles: RoleItem[] | undefined, currentUser: RoleItem | undefined) => {
  if (!btplConfRoles || !currentUser) return false
  const roleitem = btplConfRoles.filter((r) => r.managerType === 2) // 管理员，联系主持人
  if (roleitem.length > 0) {
    return roleitem.some((role) => role.roleId === currentUser.roleId) // currentUser.roleId === roleitem[0].roleId
  }
  return false
}
// 判断是否嘉宾
export const userIsGuest = (
  btplConfRoles: RoleItem[] | undefined,
  currentUser: RoleItem | undefined,
  cfgroleId: number | undefined,
) => {
  if (!btplConfRoles || !currentUser) return false
  const roleitem = btplConfRoles.filter((r) => r.managerType === 3 && r.roleId === currentUser.roleId) // 其他人
  // 判断当前活动是否是嘉宾，用角色列表中的角色和活动配置信息里的配置
  if (roleitem.length > 0) {
    return roleitem.some((role) => role.roleId == cfgroleId) // cfgroleId == roleitem[0].roleId
  }
  return false
}
// 主持人点击按钮发送跳转消息
export const sendRouterClick = (
  dispatch: any,
  sdkMessage: any,
  url: string,
  aid: string | number,
  localId: string | number,
) => {
  dispatch(savePageAddressByHostThunk({ activityId: aid, url: url }))
    .unwrap()
    .then(() => {
      sdkMessage.then((fn: any) =>
        fn({
          uuid: aid,
          destUsers: [],
          message: JSON.stringify({
            isHost: true,
            localId: localId,
            t: 20,
            m: { t: ``, p: { h: url } },
          }),
        }),
      )
    })
}
