import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IAnsweringUserInfo, ISaveActivityOpt, IUserInfo, IUTeamUsersGroupInfo } from '../../entity/host.interface'
import {
  PaperListObject,
  PaperQuestionListObj,
  PlayerRecordList,
  PlayerUserRecord,
  RecordObj,
} from '../../entity/service.interface'
import { QsyMessageInfo } from '../../entity/system.interface'
import { get, post } from '../../services'
import {
  postSaveActivityInfo,
  getActivityUsersInfo,
  getPaperList,
  postPatchUserToTeam,
  getPaperQuestionList,
  getApplyTeamMsg,
  postApplyTeamMsg,
  getPaperEnter,
  getAnswerEnter,
  getAnswerTop5,
  getDelUser,
  postQuestionIsRight,
  postQuestionStateChange,
  getFinishActivity,
  getRecordList,
  // postGetBestPerson,
  postGetSortList,
  getTeamUserList,
  getUserModalList,
  getHistoryList,
  getHistoryDetail,
  getActivityNoJoinInfo,
  postGetBestPerson,
  getAddUserScore,
  checkIfPublish,
  getInviteSpeak,
  stopInviteSpeak,
  getQuestionState,
  checkPublishSortOrPlayer,
  saveUSerIsSpeaked,
  checkUserIsSpeaking,
  savePublishSortOrPlayer,
  savePageAddressByHost,
  getPageAddressByHost,
  saveCountDownTime,
  delCountDownTime,
  updateOnlineUsers,
} from '../../services/urls'
import { setActivityInfo } from '../../store/page'
interface AlertMessage extends QsyMessageInfo {
  show: boolean
}
interface IHostState {
  uteamInfo?: IUTeamUsersGroupInfo
  noJoinUserInfo?: IUserInfo[]
  paperListInfo?: PaperListObject
  qsyCustomMessage?: QsyMessageInfo
  applyCount?: number
  applyInfo?: AlertMessage[]
}
const initHostState: IHostState = {}
// 保存活动
const postActivityThunk = createAsyncThunk('host_team_save_activity', async (opt: ISaveActivityOpt, { dispatch }) => {
  const record: any = await post({ url: `${postSaveActivityInfo}`, data: opt })
  // 将活动信息保存到store中
  const transforOpt = {
    basic: opt.basic,
    basicScore: opt.basicScore,
    groups: opt.groupInfos,
    groupCount: opt.groupCount,
    isLimit: opt.isLimit,
    limitTime: opt.limitTime,
    isShow: opt.isShow,
    minus: opt.minus,
    minusScore: opt.minusScore,
    plus: opt.plus,
    plusScore: opt.plusScore,
    qsyActivityUUID: opt.qsyActivityUUID,
    type: opt.type,
    state: 0,
    id: record,
    role: opt.role,
  }
  dispatch(setActivityInfo(transforOpt))
  return record
})

// 查询次活动中所有的团队人员
const getTeamUsersThunk = createAsyncThunk('host_uteam_get_users', async (id: string, { dispatch }) => {
  const record = await get<IUTeamUsersGroupInfo>({ url: `${getActivityUsersInfo}?activityId=${id}` })
  dispatch(updateQsyCustomMsg(undefined))
  return record
})
// 删除已经入会的成员
const getDelTeamUserThunk = createAsyncThunk(
  'host_uteam_del_user',
  async (opt: { id: string; groupId: number; userId: number }, { dispatch }) => {
    const record = await get({ url: `${getDelUser}?activityId=${opt.id}&groupId=${opt.groupId}&userId=${opt.userId}` })
    dispatch(getTeamUsersThunk(opt.id))
    return record
  },
)
// 查询所有的入会人员，不包括入会的
const getAllUsersThunk = createAsyncThunk('host_user_get_no_users', async (id: string): Promise<IUserInfo[]> => {
  const record: IUserInfo[] = await get({ url: `${getActivityNoJoinInfo}?activityId=${id}` })
  record && record.forEach((item) => (item.id = item.userId))
  // for (let i = 1; i < 10; i++) {
  //   record.push({ id: i, name: `${i}`, userId: i, tempuserid: i, accid: 0, extId: 0 })
  // }
  // console.log('rrr=====', record)
  return record
})

type SaveSomeUserToTeamObj = {
  activityId: number
  groups: { groupId: number; userId: number }[]
}
const postUserPatchToTeamThunk = createAsyncThunk('host_user_save_users', async (opt: SaveSomeUserToTeamObj) => {
  const record = await post({ url: `${postPatchUserToTeam}`, data: opt })
  return record
})

// 查询抢答的试卷列表
const getPaperListThunk = createAsyncThunk(
  'host_answer_get_list',
  async (activityId: string): Promise<PaperListObject> => {
    const record = await get<PaperListObject>({ url: `${getPaperList}?activityId=${activityId}` })
    return record
  },
)
// 查询现有消息
const getApplyTeamMsgThunk = createAsyncThunk('host_answer_get_apply_msg', async (activityId: string): Promise<any> => {
  const record = await get({ url: `${getApplyTeamMsg}?activityId=${activityId}` })
  return record
})
// 处理消息
const postApplyTeamMsgThunk = createAsyncThunk('host_answer_deal_apply_msg', async (opt: any): Promise<any> => {
  const record = await post({ url: `${postApplyTeamMsg}`, data: opt })
  return record
})
// 进入抢答
const getPaperEnterThunk = createAsyncThunk(
  'host_answer_get_paper_enter',
  async (opt: { activityId: string; paperId: string; isStart: string }): Promise<any> => {
    const record = await get({
      url: `${getPaperEnter}?activityId=${opt.activityId}&paperId=${opt.paperId}&isStart=${opt.isStart}`,
    })
    return record
  },
)
// 选择试题抢答
const getPaperQuestionEnterThunk = createAsyncThunk(
  'host_answer_get_question_enter',
  async (opt: { activityId: string; paperId: string; questionId: string; resultPaperId: string }): Promise<any> => {
    const record = await get({
      url: `${getAnswerEnter}?activityId=${opt.activityId}&paperId=${opt.paperId}&questionId=${opt.questionId}&resultPaperId=${opt.resultPaperId}`,
    })
    return record
  },
)

// 查询试卷的习题
const getPaperQuestionListThunk = createAsyncThunk(
  'host_answer_question_get_list',
  async ({
    activityId,
    paperId,
    resultPaperId,
  }: {
    activityId: string
    paperId: string
    resultPaperId: string
  }): Promise<PaperQuestionListObj> => {
    const record: any = await get({
      url: `${getPaperQuestionList}?activityId=${activityId}&paperId=${paperId}&resultPaperId=${resultPaperId}`,
    })

    return record
  },
)
// 获取抢答前5名
const getPaperQuestionTop5 = createAsyncThunk(
  'host_answer_get_question_top',
  async (opt: {
    activityId: string
    paperId: string
    questionId: string
    resultPaperId: string
  }): Promise<IAnsweringUserInfo[]> => {
    const record: any = await get({
      url: `${getAnswerTop5}?activityId=${opt.activityId}&paperId=${opt.paperId}&questionId=${opt.questionId}&resultPaperId=${opt.resultPaperId}`,
    })
    // console.log('record:', record)
    // record.forEach((r: any) => {
    //   r.icon = r.headImage
    // })
    return record // [...record, ...record, ...record, ...record, ...record]
  },
)
// 判断试题对错
const postQuestionIsRightThunk = createAsyncThunk(
  'host_answer_get_question_right',
  async (opt: {
    activityId: string
    paperId: string
    questionId: number
    correct: number
    groupId: number
    userId: number
    resultPaperId: string
  }) => {
    const record: any = await post({
      url: `${postQuestionIsRight}`,
      data: opt,
    })
    return record
  },
)
// 修改试卷状态
const postQuestionStateThunk = createAsyncThunk(
  'host_answer_get_question_right',
  async (opt: {
    activityId: string
    paperId: string
    questionId: number
    state: number
    userName: string
    resultPaperId: string
  }): Promise<IAnsweringUserInfo[]> => {
    const record: any = await post({
      url: `${postQuestionStateChange}`,
      data: opt,
    })
    return record
  },
)
//结束活动
const getFinishActivityThunk = createAsyncThunk(
  'host_answer_get_question_top',
  async (opt: { activityId: string; paperId: string; resultPaperId: string }) => {
    const record: any = await get({
      url: `${getFinishActivity}?activityId=${opt.activityId}&paperId=${opt.paperId}&resultPaperId=${opt.resultPaperId}`,
    })
    return record
  },
)

// 获取排名列表
const postGetSortListThunk = createAsyncThunk(
  'host_answer_get_sort_list',
  async (opt: { activityId: number; paperId: number; resultPaperId: number; type: number }): Promise<RecordObj[]> => {
    const record: RecordObj[] = await post({
      url: `${postGetSortList}?activityId=${opt.activityId}&paperId=${opt.paperId}&resultPaperId=${opt.resultPaperId}&type=${opt.type}`,
    })
    record.forEach((item) => {
      let showName = item.name
      // 没有中文时显示前2位
      if (item.name && /[^u4e00-u9fa5]/.test(item.name)) {
        showName = item.name.substring(item.name.length - 2)
      } else if (item.name) {
        showName = item.name.substring(0, 2)
      }
      item.showName = showName
    })
    return record
    // return [
    //   ...record,
    //   ...record,
    //   ...record,
    //   ...record,
    //   ...record,
    //   ...record,
    //   ...record,
    //   ...record,
    //   ...record,
    //   ...record,
    // ]
  },
)
// 获取最好选手
const postGetBestPersonThunk = createAsyncThunk('host_record_best_person', async (opt: any): Promise<RecordObj[]> => {
  const record: any = await post({
    url: `${postGetBestPerson}?activityId=${opt.activityId}&paperId=${opt.paperId}&resultPaperId=${opt.resultPaperId}&type=${opt.type}`,
  })
  if (!Array.isArray(record)) return [record]
  record.forEach((item) => {
    let showName = item.name
    // 没有中文时显示前2位
    if (item.name && /[^u4e00-u9fa5]/.test(item.name)) {
      showName = item.name.substring(item.name.length - 2)
    } else if (item.name) {
      showName = item.name.substring(0, 2)
    }
    item.showName = showName
  })
  return record as RecordObj[]
})

// 发布比赛结
const getRecordListThunk = createAsyncThunk(
  'host_answer_get_sort_list',
  async (opt: { activityId: number; paperId: number; resultPaperId: number; type: number }) => {
    const record = await get({
      url: `${getRecordList}?activityId=${opt.activityId}&paperId=${opt.paperId}&resultPaperId=${opt.resultPaperId}&type=${opt.type}`,
      data: opt,
    })
    return record
  },
)

// 获取团队列表
const getRecordPlayerListThunk = createAsyncThunk(
  'host_answer_get_player_list',
  async (opt: { activityId: string; paperId: string; resultPaperId: string }): Promise<PlayerRecordList[]> => {
    const record: PlayerRecordList[] = await post({
      url: `${getTeamUserList}?activityId=${opt.activityId}&paperId=${opt.paperId}&resultPaperId=${opt.resultPaperId}`,
    })
    record &&
      record.forEach((item) => {
        if (item.users) {
          item.users.forEach((user) => {
            if (user.name && /[^u4e00-u9fa5]/.test(user.name)) {
              user.showName = user.name.substring(user.name.length - 2)
            } else if (user.name) {
              user.showName = user.name.substring(0, 2)
            }
          })
          // item.users = [...item.users, ...item.users, ...item.users, ...item.users]
        }
      })
    return record
  },
)
// 获取个人列表
const getRecordPlayerUserListThunk = createAsyncThunk(
  'host_answer_get_player_user_list',
  async (opt: { activityId: string; paperId: string; resultPaperId: string }): Promise<PlayerUserRecord[]> => {
    const record: PlayerUserRecord[] = await post({
      url: `${getUserModalList}?activityId=${opt.activityId}&paperId=${opt.paperId}&resultPaperId=${opt.resultPaperId}`,
    })
    record &&
      record.forEach((user) => {
        if (user.name && /[^u4e00-u9fa5]/.test(user.name)) {
          user.showName = user.name.substring(user.name.length - 2)
        } else if (user.name) {
          user.showName = user.name.substring(0, 2)
        }
      })
    return record //[...record, ...record]
  },
)
// 获取历史列表
const getRushHistoryListThunk = createAsyncThunk(
  'host_answer_get_player_user_list',
  async (id: string): Promise<PaperListObject> => {
    const record: PaperListObject = await get({
      url: `${getHistoryList}?activityId=${id}`,
    })
    record &&
      record.papers &&
      record.papers.forEach((paper) => {
        if (paper.startTime) {
          const time = new Date(paper.startTime)
          const hour = time.getHours()
          const mini = time.getMinutes()
          paper.startTime = `${hour >= 10 ? hour : '0' + hour}:${mini >= 10 ? mini : '0' + mini}`
        }
      })
    return record
  },
)
// 获取历史详情
const getRushHistoryDetailThunk = createAsyncThunk(
  'host_answer_get_player_user_list',
  async (opt: { activityId: number; paperId: number; resultPaperId: number }) => {
    const record: any = await get({
      url: `${getHistoryDetail}?activityId=${opt.activityId}&paperId=${opt.paperId}&resultPaperId=${opt.resultPaperId}`,
    })
    return record
  },
)
// 添加分数
const getAddUserScoreThunk = createAsyncThunk(
  'host_answer_get_player_user_list',
  async (opt: {
    activityId: string
    paperId: string
    resultPaperId: string
    score: string
    userId: string
    groupId?: string
  }) => {
    let path = `${getAddUserScore}?activityId=${opt.activityId}&paperId=${opt.paperId}&resultPaperId=${opt.resultPaperId}&score=${opt.score}&userId=${opt.userId}`
    if (opt.groupId) {
      path = `${path}&groupId=${opt.groupId}`
    }
    const record: any = await get({
      url: path,
    })
    return record
  },
)

const checkIfPulishThunk = createAsyncThunk(
  'host_answer_get_player_check_pulish',
  async (opt: { activityId: string; paperId: string; resultPaperId: string }): Promise<boolean> => {
    const path = `${checkIfPublish}?activityId=${opt.activityId}&paperId=${opt.paperId}&resultPaperId=${opt.resultPaperId}`
    const record: any = await post({
      url: path,
    })
    return record.isPublish
  },
)
const saveSpeakedUserThunk = createAsyncThunk('host_answer_save_speaked', async (opt: any): Promise<boolean> => {
  const path = `${getInviteSpeak}`
  const record: any = await post({
    url: path,
    data: opt,
  })
  return record
})
const saveStopSpeakedUserThunk = createAsyncThunk(
  'host_answer_save_stop_speaked',
  async (opt: any): Promise<boolean> => {
    const path = `${stopInviteSpeak}`
    const record: any = await post({
      url: path,
      data: opt,
    })
    return record
  },
)
const getQuestionStateThunk = createAsyncThunk('get_question_state', async (opt: any) => {
  const path = `${getQuestionState}?activityId=${opt.activityId}&paperId=${opt.paperId}&resultPaperId=${opt.resultPaperId}&questionId=${opt.questionId}`
  const record: any = await get({
    url: path,
  })
  return record
})

const getPublishSortOrPlayerThunk = createAsyncThunk('save_publish_sort_player', async (opt: any): Promise<number> => {
  const path = `${checkPublishSortOrPlayer}`
  const record: any = await post({
    url: `${path}?resultPaperId=${opt.resultPaperId}`,
  })
  return record
})
// 保存发布列表页面
const savePublishSortOrPlayerThunk = createAsyncThunk(
  'save_publish_sort_player_state',
  async (opt: any): Promise<number> => {
    const path = `${savePublishSortOrPlayer}`
    const record: any = await post({
      url: `${path}?resultPaperId=${opt.resultPaperId}&state=${opt.state}`,
    })
    return record
  },
)

// 保存用户发言状态
const saveUserSpeakingThunk = createAsyncThunk('set_user_speaking_state', async (opt: any) => {
  const path = `${saveUSerIsSpeaked}?speak=${opt.speak}&userId=${opt.userId}`
  const record: any = await get({
    url: path,
  })
  return record
})
// 获取用户发言
const getUserSpeakingThunk = createAsyncThunk('get_user_speaking_state', async (opt: any) => {
  const path = `${checkUserIsSpeaking}?userId=${opt.userId}`
  const record: any = await get({
    url: path,
  })
  return record
})
const savePageAddressByHostThunk = createAsyncThunk('save_host_page_address', async (opt: any) => {
  const path = `${savePageAddressByHost}?activityId=${opt.activityId}`
  const record: any = await post({
    url: path,
    data: opt,
  })
  return record
})
const getPageAddressByHostThunk = createAsyncThunk('get_host_page_address', async (opt: any) => {
  const path = `${getPageAddressByHost}?activityId=${opt.activityId}`
  const record: any = await get({
    url: path,
  })
  return record
})
// 提交活动倒计时
const postCountDownTimeThunk = createAsyncThunk('save_count_down_time', async (opt: any) => {
  const path = `${saveCountDownTime}?resultPaperId=${opt.rid}&time=${opt.time}`
  const record: any = await post({
    url: path,
  })
  return record
})
// 删除活动倒计时
const delCountDownTimeThunk = createAsyncThunk('del_count_down_time', async (opt: any) => {
  const path = `${delCountDownTime}?resultPaperId=${opt.rid}`
  const record: any = await post({
    url: path,
  })
  return record
})
// 获取在线用户列表
const updateOnlineUsersThunk = createAsyncThunk('update_online_users', async (opt: any) => {
  const users = opt.users.map((user: any) => ({
    accid: user.accid,
    email: user.email,
    extId: user.extId,
    headImage: user.imagePath,
    mobile: user.mobile,
    name: user.name,
    phoneNum: user.phoneNum,
    qsyActivityUUID: opt.conferenceId,
    qsyUUID: `${user.tempUserId}` || `${user.accid}`,
    role: user.confRole.roleId,
    tempuserid: `${user.tempUserId}`,
  }))
  const path = `${updateOnlineUsers}?qsyActivityUUID=${opt.conferenceId}`
  const record: any = await post({
    url: path,
    data: users,
  })
  return record
})

const hostSlice = createSlice({
  name: 'host',
  initialState: initHostState,
  reducers: {
    updateQsyCustomMsg: (state, actions) => {
      state.qsyCustomMessage = actions.payload
    },
    updateApplyCount: (state, actions) => {
      state.applyCount = actions.payload
    },
    updateApplyInfo: (state, actions) => {
      state.applyInfo = actions.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamUsersThunk.fulfilled, (state, actions) => {
        state.uteamInfo = actions.payload
      })
      .addCase(getAllUsersThunk.fulfilled, (state, actions) => {
        state.noJoinUserInfo = actions.payload
      })
      .addCase(getPaperListThunk.fulfilled, (state, actions) => {
        const payload = actions.payload
        if (payload && payload.papers) {
          payload.papers.forEach((r) => {
            const data = new Date(r.createTime)
            const month = data.getMonth() + 1
            const date = data.getDate()
            r.createTime = `${month >= 10 ? month : '0' + month} / ${date > 10 ? date : '0' + date}`
          })
        }
        state.paperListInfo = actions.payload
      })
  },
})
export const reducer = hostSlice.reducer
export const { updateQsyCustomMsg, updateApplyCount, updateApplyInfo } = hostSlice.actions
export {
  postUserPatchToTeamThunk,
  getPaperListThunk,
  postActivityThunk,
  getTeamUsersThunk,
  getAllUsersThunk,
  getPaperQuestionListThunk,
  getApplyTeamMsgThunk,
  postApplyTeamMsgThunk,
  getPaperEnterThunk,
  getPaperQuestionEnterThunk,
  getPaperQuestionTop5,
  getDelTeamUserThunk,
  postQuestionIsRightThunk,
  postQuestionStateThunk,
  getFinishActivityThunk,
  postGetSortListThunk,
  getRecordListThunk,
  postGetBestPersonThunk,
  getRecordPlayerListThunk,
  getRecordPlayerUserListThunk,
  getRushHistoryListThunk,
  getRushHistoryDetailThunk,
  getAddUserScoreThunk,
  checkIfPulishThunk,
  saveSpeakedUserThunk,
  saveStopSpeakedUserThunk,
  getQuestionStateThunk,
  getPublishSortOrPlayerThunk,
  saveUserSpeakingThunk,
  getUserSpeakingThunk,
  savePublishSortOrPlayerThunk,
  savePageAddressByHostThunk,
  getPageAddressByHostThunk,
  postCountDownTimeThunk,
  delCountDownTimeThunk,
  updateOnlineUsersThunk,
}
