import { createSlice } from '@reduxjs/toolkit'

interface SepectatorStateInfo {
  showHeader: boolean
}
const initialState: SepectatorStateInfo = {
  showHeader: true,
}
const guestSlice = createSlice({
  name: 'sepectator',
  initialState,
  reducers: {
    setShowHeader: (state, actions) => {
      state.showHeader = actions.payload
    },
  },
})
export const reducer = guestSlice.reducer
export const { setShowHeader } = guestSlice.actions
