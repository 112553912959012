import axios from 'axios'
import { DataObject } from '../entity/service.interface'
import { BaseError } from '../entity/system.interface'
import { log } from '../utils/utils'
const instance = axios.create({
  baseURL: process.env.REACT_APP_LOCAL_API,
  timeout: 5000,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})
export type requestOpt = {
  url: string
  baseUrl?: string
  params?: { [key: string]: any }
  data?: { [key: string]: any }
  withCredentials?: boolean
}
const isBaseError = (error: any): error is BaseError => error instanceof BaseError
export const get = async <T>(opt: requestOpt) => {
  try {
    const newOpt = Object.assign({}, opt, {
      headers: {
        userid: customSession.getItem('local_user_id') || '',
        qsyuserid: customSession.getItem('qsy_user_id') || '',
      },
    })
    const ret = await instance.get<DataObject<T>>(opt.url, newOpt)
    if (ret.data.code !== 1) {
      throw new BaseError(ret.data.message)
    }
    log({ backdata: JSON.stringify(ret.data), source: 'get service', param: JSON.stringify(opt) })
    return ret.data.data
  } catch (e: any) {
    log({ error: e.message, param: JSON.stringify(opt) })
    if (isBaseError(e)) {
      throw e.message
    } else {
      throw '请求数据报错，请稍后'
    }
  }
}
export const post = async <T>(opt: requestOpt) => {
  try {
    const ret = await instance.post<DataObject<T>>(opt.url, opt.data, {
      headers: {
        userid: customSession.getItem('local_user_id') || '',
        qsyuserid: customSession.getItem('qsy_user_id') || '',
      },
    })
    if (ret.data.code !== 1) {
      throw new BaseError(ret.data.message)
    }
    log({ backdata: JSON.stringify(ret.data), source: 'post service', param: JSON.stringify(opt) })
    return ret.data.data
  } catch (e: any) {
    log({ error: e.message, param: JSON.stringify(opt) })
    if (isBaseError(e)) {
      throw e.message
    } else {
      throw '请求数据报错，请稍后'
    }
  }
}
