export default class MemoryStorage {
  data: any
  public readonly length: number
  constructor() {
    this.data = {}
    this.length = 0
  }
  /**
   * @name 保存
   * @param name 键名
   * @param value 键值
   */
  setItem(name: string, value: string) {
    this.data[name] = value
  }
  /**
   * @name 读取
   * @param name 键名
   */
  getItem(name: string) {
    return this.data[name]
  }
  /**
   * @name 删除
   * @param name 键名
   */
  removeItem(name: string) {
    delete this.data[name]
  }
  key(index: number) {
    const keys = Object.keys(this.data)
    return keys[index]
  }
  clear() {
    this.data = {}
  }
}
