import React from 'react'
import ReactDOM from 'react-dom'
import { store } from './store'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './design/all.scss'
import App from './App'
import ErrorBoundary from './error'
import MemoryStorage from './utils/memory'
import { log } from './utils/utils'
// import reportWebVitals from './reportWebVitals'

const root = process.env.PUBLIC_URL
window.addEventListener('error', (event) => {
  log({ error: JSON.stringify(event) })
  return false
})
// 处理异步错误
window.addEventListener('unhandledrejection', (event) => {
  log({ unhandledrejection: JSON.stringify(event) })
  return false
})
try {
  window.customSession = window.sessionStorage
  window.customLocal = window.localStorage
  log({ storage: 'window' })
} catch (e) {
  window.customSession = new MemoryStorage()
  window.customLocal = new MemoryStorage()
  log({ storage: 'custom' })
}
ReactDOM.render(
  <>
    <ErrorBoundary>
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter basename={root}>
            <App />
          </BrowserRouter>
        </Provider>
      </React.StrictMode>
    </ErrorBoundary>
  </>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
