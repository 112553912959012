import React from 'react'
import './circle.scss'

const LoadingComponent = () => {
  const isH5 = location.pathname.includes('/h5')
  return (
    <div className={`loading-paper ${isH5 ? 'app' : ''}`}>
      <div className="loading-siper-circle-wrapper">
        <div className={`loading-siper-circle`}></div>
      </div>
    </div>
  )
}
export default LoadingComponent
