import React, { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import Loading from './components/loading'
import Circle from './components/loading/circle'
import { log } from './utils/utils'
const ConfigIndex = lazy(() => import('./views/config'))
const ConfigRoute = lazy(() => import('./views/config/setting'))
const PaperRoute = lazy(() => import('./views/config/paper'))
const HostRoomRoute = lazy(() => import('./views/host'))
const HostTeamStartRoute = lazy(() => import('./views/host/team'))
const HostTeamUserRoute = lazy(() => import('./views/host/teamanduser'))
const HostTeamAddUserRoute = lazy(() => import('./views/host/teamanduser/addUser'))
const HostRushAnswerPageRoute = lazy(() => import('./views/host/answer'))
const HostRushAnswerListPageRoute = lazy(() => import('./views/host/answer/list'))
const HostRecordListPageRoute = lazy(() => import('./views/host/record'))
const HostPlayerListPageRoute = lazy(() => import('./views/host/record/playerList'))
const HostHistoryListPageRoute = lazy(() => import('./views/host/record/history'))

// const GuestLoadingPageRoute = lazy(() => import('./views/guest/loading'))
const HostGuestIndexPageRoute = lazy(() => import('./views/guest'))
const HostGuestTeamPageRoute = lazy(() => import('./views/guest/team'))
const HostGuestQuestionPageRoute = lazy(() => import('./views/guest/questions'))
const GuestSortListPageRoute = lazy(() => import('./views/guest/record/sort'))
const GuestPlayerReordPageRoute = lazy(() => import('./views/guest/record/player'))
const GuestPlayerListPageRoute = lazy(() => import('./views/guest/record/rushDetail'))

const SpectIndexPageRoute = lazy(() => import('./views/spectator'))
// const SpectLoadingPageRoute = lazy(() => import('./views/spectator/loading'))
// const SpecTeamPageRoute = lazy(() => import('./views/spectator/team'))
const SpecuestionPageRoute = lazy(() => import('./views/spectator/questions'))
const SpecSortListPageRoute = lazy(() => import('./views/spectator/record/sort'))
const SpecPlayerReordPageRoute = lazy(() => import('./views/spectator/record/player'))
const SpecPlayerListPageRoute = lazy(() => import('./views/spectator/record/rushDetail'))

const H5HomePageRoute = lazy(() => import('./views/h5'))
const H5StartPageRoute = lazy(() => import('./views/h5/team'))
const H5SortListPageRoute = lazy(() => import('./views/h5/record/sort'))
const H5PlayerReordPageRoute = lazy(() => import('./views/h5/record/player'))
const H5PlayerListPageRoute = lazy(() => import('./views/h5/record/rushDetail'))
const H5GuestQuestionPageRoute = lazy(() => import('./views/h5/questions'))

const H5SpecPageRoute = lazy(() => import('./views/h5/spectator'))
// const H5SpecLoadingPageRoute = lazy(() => import('./views/h5/spectator/loading'))
const H5SpecQuestionPageRoute = lazy(() => import('./views/h5/spectator/questions'))
const H5SpecSortListPageRoute = lazy(() => import('./views/h5/spectator/record/sort'))
const H5SpecPlayerReordPageRoute = lazy(() => import('./views/h5/spectator/record/player'))
const H5SpecPlayerListPageRoute = lazy(() => import('./views/h5/spectator/record/rushDetail'))

// import NotFound from './views/404'

const App_Version = '2022-11-20 13:11:05'
function App() {
  log({ 'app init success, version is:': App_Version })
  const pathname = location.pathname
  const isCfg = pathname.includes('/config')
  // isCfg = true
  return (
    <Suspense fallback={!isCfg ? <Circle /> : <Loading />}>
      <Routes>
        <Route path="/config" element={<ConfigIndex />}>
          <Route path="setting" element={<ConfigRoute />}>
            <Route path=":id" element={<ConfigRoute />} />
          </Route>
          <Route path="paper" element={<PaperRoute />}></Route>
        </Route>
        <Route path="/host">
          <Route path="room" element={<HostRoomRoute />}>
            <Route path="team" element={<HostTeamStartRoute />} />
            {/* <Route path="team" element={<Circle />} /> */}
            <Route path="uteam" element={<HostTeamUserRoute />} />
            <Route path="add" element={<HostTeamAddUserRoute />} />
            <Route path="rush" element={<HostRushAnswerPageRoute />} />
            {/* <Route path="rush/:redirect" element={<HostRushAnswerPageRoute />} /> */}
            <Route path="answer/:paperId/:activityId/:resultId" element={<HostRushAnswerListPageRoute />} />
            <Route path="record/list" element={<HostRecordListPageRoute />} />
            <Route path="record/player/:paperId/:resultId/:activityId" element={<HostPlayerListPageRoute />} />
            <Route path="record/history" element={<HostHistoryListPageRoute />} />
          </Route>
        </Route>
        <Route path="/guest" element={<HostGuestIndexPageRoute />}>
          {/* <Route index element={<GuestLoadingPageRoute />}></Route> */}
          <Route path="team" element={<HostGuestTeamPageRoute />}></Route>
          <Route path="question/:paperId/:questionId/:resultId" element={<HostGuestQuestionPageRoute />}></Route>
          <Route path="record/sort" element={<GuestSortListPageRoute />}></Route>
          <Route path="record/player/:paperId/:resultId/:activityId" element={<GuestPlayerReordPageRoute />}></Route>
          <Route path="record/rushdetail/:paperId/:resultId/:activityId" element={<GuestPlayerListPageRoute />}></Route>
        </Route>
        <Route path="/sepectator" element={<SpectIndexPageRoute />}>
          {/* <Route index element={<SpectLoadingPageRoute />}></Route> */}
          <Route path="question/:paperId/:questionId/:resultId" element={<SpecuestionPageRoute />}></Route>
          <Route path="record/sort" element={<SpecSortListPageRoute />}></Route>
          <Route path="record/player/:paperId/:resultId/:activityId" element={<SpecPlayerReordPageRoute />}></Route>
          <Route path="record/rushdetail/:paperId/:resultId/:activityId" element={<SpecPlayerListPageRoute />}></Route>
        </Route>
        <Route path="/h5" element={<H5HomePageRoute />}>
          <Route path="team" element={<H5StartPageRoute />}></Route>
          <Route path="question/:paperId/:questionId/:resultId" element={<H5GuestQuestionPageRoute />}></Route>
          <Route path="record/sort" element={<H5SortListPageRoute />}></Route>
          <Route path="record/player/:paperId/:resultId/:activityId" element={<H5PlayerReordPageRoute />}></Route>
          <Route path="record/rushdetail/:paperId/:resultId/:activityId" element={<H5PlayerListPageRoute />}></Route>
          <Route path="sepectator" element={<H5SpecPageRoute />}>
            {/* <Route index element={<H5SpecLoadingPageRoute />}></Route> */}
            <Route path="question/:paperId/:questionId/:resultId" element={<H5SpecQuestionPageRoute />}></Route>
            <Route path="record/sort" element={<H5SpecSortListPageRoute />}></Route>
            <Route path="record/player/:paperId/:resultId/:activityId" element={<H5SpecPlayerReordPageRoute />}></Route>
            <Route
              path="record/rushdetail/:paperId/:resultId/:activityId"
              element={<H5SpecPlayerListPageRoute />}
            ></Route>
          </Route>
        </Route>
        <Route path="*" element={isCfg ? <Loading /> : <Circle />}></Route>
      </Routes>
    </Suspense>
  )
}

export default App
