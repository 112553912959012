import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ISaveUserOpt } from '../entity/host.interface'
import { ActivityInfoObj } from '../entity/service.interface'
import { ILocalUserInfo, IqshMettingInfoObj, IqsyUserInfoObj } from '../entity/system.interface'
import { get, post } from '../services'
import {
  getActivityInfo,
  getCacheDataByActivity,
  offlineUser,
  postActivityState,
  postCacheDataByActivity,
  postUserSave,
} from '../services/urls'
export interface PageHeaderInfo {
  // 页面主标题
  pageTitle: string
  // 页面次标题
  pageSubTitle?: string
  // 是否显示标题栏
  showHeader?: boolean
  // 是否显示返回按钮
  showBackIcon?: boolean
  showRightClose?: boolean
  showLeftClose?: boolean
  showMenu?: boolean
  // h5使用
  showLeftTitle?: boolean
  // h5使用 详细按钮
  showDetail?: boolean
  // h5使用 中间标题
  showContent?: boolean
}
interface ICacheData {
  // paper id
  pid: string
  // activity id
  aid: string
  // question id
  qid?: string
  // resultpaper id
  rid?: string
}
export type PageStateInfo = {
  // 加载框
  loading: boolean
  // 错误信息
  error: string | null
  // qsy用户信息
  qsyUserInfo?: IqsyUserInfoObj
  // qsy主持人信息
  // qsyHostUserInfo?: IqsyUserInfoObj[]
  // qsy联席主持人信息
  // qsyHostLinkUserInfo?: IqsyUserInfoObj[]
  // qsy所有入会的用户信息
  // qsyAllUserInfo?: IqsyUserInfoObj[]
  // 本地数据库存储的用户信息
  localUserInfo?: ILocalUserInfo
  // 数据库获取的活动信息
  activityInfo?: ActivityInfoObj
  mettingInfo?: IqshMettingInfoObj
  // 团队信息
  teamInfo?: { name: string; number: number }[]
  // 活动状态,:初始状态5 -1-> 还未配置；0->配置人员；1->未开始；4-> 进入抢答，但抢答还没开始; 2->进行中；3->已结束
  state: 0 | 1 | 2 | 3 | 4 | 5
  pageHeader: PageHeaderInfo
  showRecoredMenu?: boolean // 是否显示公布结果和结束活动菜单
  cacheData?: ICacheData
  /* 用户角色1：主持人 2:联席主持人 3: 嘉宾 4:观众*/
  roles: { role: 1 | 2 | 3 | 4; isGuest: boolean }
  isGroup?: boolean // 是否已经入团队
  theme: 'dark' | 'light' // 主题色
  joinInUsers: IqsyUserInfoObj[] // 参会人员的列表
}
const initialState: PageStateInfo = {
  loading: false,
  error: null,
  teamInfo: [],
  state: 5,
  pageHeader: {
    // 页面主标题
    pageTitle: '',
    // 是否显示标题栏
    showHeader: true,
    showRightClose: true,
  },
  roles: { role: 4, isGuest: false },
  theme: 'light',
  joinInUsers: [],
}

// 获取保存活动信息
const getActivityThunk = createAsyncThunk(
  'host_team_get_activity',
  async (qsyUid: string): Promise<ActivityInfoObj> => {
    const record = await get<ActivityInfoObj>({ url: `${getActivityInfo}/${qsyUid}` })
    return record
  },
)
// 保存人员
const saveUserThunk = createAsyncThunk('host_team_save_user', async (opt: ISaveUserOpt): Promise<ILocalUserInfo> => {
  const record = await post<ILocalUserInfo>({ url: `${postUserSave}`, data: opt })
  return record
})
// 保存入会人员
const saveUserMettingThunk = createAsyncThunk(
  'host_team_save_user_metting',
  async (opt: ISaveUserOpt): Promise<ILocalUserInfo> => {
    console.log('save other users')
    const record = await post<ILocalUserInfo>({ url: `${postUserSave}`, data: opt })
    return record
  },
)
// 修改活动的状态
const changeActivitySateThunk = createAsyncThunk(
  'web_activity_state_change',
  async (opt: { id: string; state: number }, { dispatch }) => {
    const record = await post({ url: `${postActivityState}`, data: opt })
    dispatch(setAcitivityState(opt.state))
    return record
  },
)
// 保存缓存信息
const saveCacheDataThunk = createAsyncThunk('cache_state_save', async (opt: ICacheData & { aid: string }) => {
  const record = await post({
    url: `${postCacheDataByActivity}?activityId=${opt.aid}`,
    data: { jsonObject: JSON.stringify(opt) },
  })
  return record
})
// 获取缓存信息
const getCacheDataThunk = createAsyncThunk('cache_state_get', async (aid: string): Promise<ICacheData | undefined> => {
  const record: any = await post({ url: `${getCacheDataByActivity}?activityId=${aid}` })
  if (record) {
    return JSON.parse(record.jsonObject) as ICacheData
  } else return undefined
})
// 离线时调用
const getOfflienUserThunk = createAsyncThunk('offline_user_get', async (opt: any) => {
  const record: any = await post({ url: `${offlineUser}`, data: opt })
  return record
})
// 获取此人是否已经抢答
// const getIsRushDataThunk = createAsyncThunk('cache_state_get', async (opt: any): Promise<ICacheData | undefined> => {
//   const record: any = await post({ url: `${getPersonIsRush}`, data: opt })
//   return record
// })
const pageSlice = createSlice({
  name: 'page',
  initialState: initialState,
  reducers: {
    changeLoading: (state, actions) => {
      state.loading = actions.payload
    },
    changeError: (state, actions) => {
      state.error = actions.payload
    },
    setUserInfo: (state, actions) => {
      state.qsyUserInfo = actions.payload
    },
    // setAllUserInfo: (state, actions) => {
    //   state.qsyAllUserInfo = actions.payload
    // },
    setMettingInfo: (state, actions) => {
      state.mettingInfo = actions.payload
    },
    setTeamInfo: (state, actions) => {
      state.teamInfo = actions.payload
    },
    setPageHeaderInfo: (state, actions) => {
      state.pageHeader = { ...state.pageHeader, ...actions.payload }
    },
    setActivityInfo: (state, actions) => {
      state.activityInfo = actions.payload
    },
    setAcitivityState: (state, actions) => {
      state.state = actions.payload
    },
    // setHostUserInfo: (state, actions) => {
    //   const saves: IqsyUserInfoObj[] = []
    //   let payload: IqsyUserInfoObj[] = []
    //   if (!Array.isArray(actions.payload)) {
    //     payload = [actions.payload]
    //   } else {
    //     payload = actions.payload
    //   }
    //   payload.forEach((d: IqsyUserInfoObj) => {
    //     const isExit = state.qsyHostUserInfo?.filter(
    //       (r) =>
    //         r.tempUserId === d.tempUserId ||
    //         (r.email && d.email && r.email === d.email) ||
    //         (r.extId && d.extId && r.extId === d.extId) ||
    //         (r.mobile && d.mobile && r.mobile === d.mobile),
    //     )
    //     if (!isExit || isExit.length <= 0) {
    //       saves.push(d)
    //     }
    //   })
    //   state.qsyHostUserInfo = saves
    // },
    // setHostLinkUserInfo: (state, actions) => {
    //   const saves: IqsyUserInfoObj[] = []
    //   let payload: IqsyUserInfoObj[] = []
    //   if (!Array.isArray(actions.payload)) {
    //     payload = [actions.payload]
    //   } else {
    //     payload = actions.payload
    //   }
    //   payload.forEach((d: IqsyUserInfoObj) => {
    //     const isExit = state.qsyHostLinkUserInfo?.filter(
    //       (r) =>
    //         r.tempUserId === d.tempUserId ||
    //         (r.email && d.email && r.email === d.email) ||
    //         (r.extId && d.extId && r.extId === d.extId) ||
    //         (r.mobile && d.mobile && r.mobile === d.mobile),
    //     )
    //     if (!isExit || isExit.length <= 0) {
    //       saves.push(d)
    //     }
    //   })
    //   state.qsyHostLinkUserInfo = actions.payload
    // },
    setShowHeaderRecordMenu: (state, actions) => {
      state.showRecoredMenu = actions.payload
    },
    setUserRole: (state, actions) => {
      state.roles = actions.payload
    },
    updateUserGroup: (state, actions) => {
      state.isGroup = actions.payload
    },
    updateTheme: (state, actions) => {
      state.theme = actions.payload
    },
    updateJoinUserList: (state, actions) => {
      state.joinInUsers = actions.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActivityThunk.fulfilled, (state, actions) => {
        const info = actions.payload
        let payload: any = info
        if (payload) {
          payload = {
            basic: payload.basic,
            basicScore: payload.basicScore,
            groups: payload.groups,
            groupCount: payload.groups.length,
            isLimit: payload.isLimit,
            limitTime: payload.limitTime,
            isShow: payload.isShow,
            minus: payload.minus,
            minusScore: payload.minusScore,
            plus: payload.plus,
            plusScore: payload.plusScore,
            qsyActivityUUID: payload.qsyActivityUUID,
            type: payload.type,
            state: payload.state,
            id: payload.id,
            role: payload.role,
          }
        }
        state.activityInfo = payload
      })
      .addCase(saveUserThunk.fulfilled, (state, actions) => {
        state.localUserInfo = actions.payload
      })
      .addCase(getCacheDataThunk.fulfilled, (state, actions) => {
        state.cacheData = actions.payload
      })
  },
})
export const reducer = pageSlice.reducer
export const {
  setActivityInfo,
  changeLoading,
  setMettingInfo,
  changeError,
  setUserInfo,
  setTeamInfo,
  setAcitivityState,
  // setHostUserInfo,
  // setHostLinkUserInfo,
  setPageHeaderInfo,
  setShowHeaderRecordMenu,
  setUserRole,
  updateUserGroup,
  updateTheme,
  updateJoinUserList,
} = pageSlice.actions
export {
  getActivityThunk,
  saveUserThunk,
  changeActivitySateThunk,
  saveCacheDataThunk,
  getCacheDataThunk,
  getOfflienUserThunk,
  saveUserMettingThunk,
}
