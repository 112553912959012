import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PaperConfigAjaxData, PaperConfigData, PaperObj, QuestionObject } from '../../../entity/config.interface'
import { changeError } from '../../../store/page'
import { get, post } from '../../../services'
import {
  getDelPaper,
  getPaperListJson,
  getPaperQuestion,
  postPaperOnActivity,
  postSavePaper,
} from '../../../services/urls'
import { label_option_list } from '../../../entity/system.interface'

interface PaperStateObject {
  list: PaperObj[]
  listTotalCount: number
  loading: boolean
  settingCanModify: boolean
}
const initPaperState: PaperStateObject = {
  list: [],
  listTotalCount: 1,
  loading: false,
  settingCanModify: true,
}
const getListThunk = createAsyncThunk(
  'paper_get_list',
  async ({ size, page, openuid }: { size: number; page: number; openuid: string }) => {
    const record = await get<PaperConfigAjaxData<PaperConfigData>>({
      url: `${getPaperListJson}/${size}/${page}?openuid=${openuid}`,
    })
    const list = record.records.map((r, i) => ({ key: i, ...r }))
    return { list: list, totale: record.total }
  },
)
const postPaperSaveThunk = createAsyncThunk('paper_save_info', async (opt: any) => {
  const record = await post({ url: `${postSavePaper}`, data: opt })
  return record
})
const getPaperDelThunk = createAsyncThunk('paper_save_info', async (opt: number[]) => {
  const q: any = []
  opt.forEach((o: number) => q.push(get({ url: `${getDelPaper}/${o}` })))
  const record = await Promise.all(q)
  return record
})
// 获取试题
const getPaperQuestionThunk = createAsyncThunk('paper_get_question', async (opt: number): Promise<QuestionObject[]> => {
  const records: any = await get({ url: `${getPaperQuestion}?paperId=${opt}` })
  const res: QuestionObject[] = records.map((record: any) => ({
    _id: record.id,
    id: record.id,
    type: record.type === 0 ? 'radio' : record.type === 1 ? 'check' : 'input', // 多选题，单选题，填空题
    // 题干
    question: record.subject,
    // 分数
    score: record.score,
    // 解析
    parsing: record.analysis,
    // 选项
    options: record.items.map((item: any) => ({
      id: item.itemId,
      lable: label_option_list[item.itemNumber], // A B C D
      text: item.content, // 选项描述
      isSuccess: item.correct, // 是否是正确答案
    })),
    // 习题编号
    number: record.number,
  }))
  return res
})
// 判断是否可以进行修改试卷
const postActivityCanModifyThunk = createAsyncThunk('activity_can_modify', async (id: string) => {
  const record = await post({ url: `${postPaperOnActivity}?templateid=${id}` })
  // console.log('record----', record)
  return record
})

const slice = createSlice({
  name: 'paper',
  initialState: initPaperState,
  reducers: {
    setRecord: (state, actions) => {
      const payload = actions.payload
      let newQuestionList = [...(state.list || [])]
      if (newQuestionList.length <= 0) {
        newQuestionList = [payload.info]
      } else {
        if (payload.isDel) {
          // 可以批量删除
          payload.info.id.forEach((id: any) => {
            const index = newQuestionList.findIndex((r) => r.id === id)
            if (index > -1) {
              newQuestionList.splice(index, 1)
            }
          })
        } else {
          const index = newQuestionList.findIndex((r) => r.id === payload.info.id)
          // 如果试卷的id没有找到
          if (index < 0) {
            newQuestionList.unshift(payload.info)
          } else {
            if (index > -1) {
              newQuestionList.splice(index, 1, payload.info)
            }
          }
        }
      }
      state.list = newQuestionList
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListThunk.fulfilled, (state, action) => {
        const list = action.payload.list
        state.list = list.map((l) => {
          const time = new Date(l.createTime)
          return {
            _id: l.id,
            id: l.id,
            name: l.name,
            time: `${time.getFullYear()}-${
              time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
            }-${time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()} ${
              time.getHours() >= 10 ? time.getHours() : '0' + time.getHours()
            }:${time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes()}:${
              time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds()
            }`,
            score: l.score,
            questionCount: l.questionCount,
            questionList: l.questionList,
          }
        })
        state.listTotalCount = action.payload.totale
        state.loading = false
      })
      .addCase(getListThunk.pending, (state, _) => {
        state.loading = true
      })
      .addCase(getListThunk.rejected, (state) => {
        state.loading = false
        changeError('获取试卷列表出错')
      })
      .addCase(postActivityCanModifyThunk.fulfilled, (state, actions) => {
        /*
         * 为了诗和远方，备注以及log不要删了
         * 状态:0->配置人员；1->未开始；2->进行中；3->已结束;4->未进入抢答;5->初始状态；
         */
        // eslint-disable-next-line no-console
        console.log(
          'on check modify status: payload is %s, state.settingCanmodify is %s  ',
          actions.payload,
          state.settingCanModify,
        )
        if (actions.payload === 5) {
          state.settingCanModify = true
        } else {
          state.settingCanModify = false
        }
      })
  },
})
export const reducer = slice.reducer
export const { setRecord } = slice.actions
export { getListThunk, postPaperSaveThunk, getPaperDelThunk, getPaperQuestionThunk, postActivityCanModifyThunk }
