import React from 'react'
import './loading.scss'
const LoadingComponent = () => {
  const isCfg = location.pathname.includes('/config')
  // console.log('show spin')
  return (
    <div className={`loading-paper ${isCfg ? 'config' : ''}`}>
      <div className={`loading-siper-spin ${isCfg ? 'config' : ''}`}></div>
    </div>
  )
}
export default LoadingComponent
