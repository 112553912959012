import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PaperQuestionListObj } from '../../entity/service.interface'
import { QsyMessageInfo } from '../../entity/system.interface'
import { get, post } from '../../services'
import {
  addUserBaseScore,
  getApplyEnterTeam,
  getCountDownTime,
  getPaperQuestionListByGuest,
  getPersonTeamScore,
  getUserApplyTeam,
  getUserTeam,
  postSubmitRush,
  postUserToTeam,
} from '../../services/urls'
import { updateUserGroup } from '../../store/page'
interface GuestStateInfo {
  qsyCustomMessage?: QsyMessageInfo
}
const initialState: GuestStateInfo = {}
// 保存单个人员到团队
type SaveUserToTeamObj = {
  activityId: string
  groupId: string
  userId: string
}
const postUserToTeamThunk = createAsyncThunk('guest_user_to_team', async (opt: SaveUserToTeamObj) => {
  const record = await get({
    url: `${postUserToTeam}?activityId=${opt.activityId}&groupId=${opt.groupId}&userId=${opt.userId}`,
  })
  return record
})
const getApplyUserToTeamThunk = createAsyncThunk('guest_user_apply_team', async (opt: SaveUserToTeamObj) => {
  const record = await get({
    url: `${getApplyEnterTeam}?activityId=${opt.activityId}&groupId=${opt.groupId}&userId=${opt.userId}`,
  })
  return record
})
// 获取个人团队
const getUserWithGroupThunk = createAsyncThunk(
  'guest_user_within_team',
  async (opt: { activityId: string; userId: string }, { dispatch }) => {
    const record: any = await get({
      url: `${getUserTeam}?activityId=${opt.activityId}&userId=${opt.userId}`,
    })
    if (record) {
      dispatch(updateUserGroup(!!record.groupId))
    }
    return record
  },
)
// 获取某个人是否已经申请，和申请ton过
const getUserApplyWithGroupThunk = createAsyncThunk(
  'guest_user_apply_within_team',
  async (opt: { activityId: string; userId: string }) => {
    const record: any = await get({
      url: `${getUserApplyTeam}?activityId=${opt.activityId}&userId=${opt.userId}`,
    })
    return record
  },
)
// 点击抢答时
const postSubmitRushThunk = createAsyncThunk(
  'guest_user_submit_rush',
  async (opt: {
    activityId: string
    userId: string
    groupId: string | undefined
    paperId: string
    questionId: string
    resultPaperId: string
  }) => {
    const record = await post({
      url: `${postSubmitRush}`,
      data: opt,
    })
    return record
  },
)

// 查询试卷的习题
const getPaperQuestionListByGuestThunk = createAsyncThunk(
  'host_answer_question_get_list',
  async ({
    activityId,
    paperId,
    questionId,
    resultPaperId,
    groupId,
    userId,
  }: {
    activityId: string
    paperId: string
    questionId: string
    resultPaperId: string
    groupId?: string
    userId?: string
  }): Promise<PaperQuestionListObj> => {
    let path = `${getPaperQuestionListByGuest}?activityId=${activityId}&paperId=${paperId}&questionId=${questionId}&resultPaperId=${resultPaperId}`
    if (groupId) {
      path = `${path}&groupId=${groupId}`
    } else if (userId) {
      path = `${path}&userId=${userId}`
    }
    const record: any = await get({
      url: path,
    })

    return record
  },
)
const addUserBaseScoreThunk = createAsyncThunk(
  'geust_get_base_score',
  async ({
    activityId,
    paperId,
    resultPaperId,
    userId,
  }: {
    activityId: string
    paperId: string
    userId: string
    resultPaperId: string
  }): Promise<PaperQuestionListObj> => {
    const record: any = await get({
      url: `${addUserBaseScore}?activityId=${activityId}&paperId=${paperId}&userId=${userId}&resultPaperId=${resultPaperId}`,
    })
    return record
  },
)

const getPersonTeamScoreThunk = createAsyncThunk(
  'geust_get_user_team_score',
  async ({
    activityId,
    paperId,
    resultPaperId,
    userId,
    groupId,
  }: {
    activityId: string
    paperId: string
    userId?: string
    resultPaperId: string
    groupId?: string
  }): Promise<number> => {
    let path = `${getPersonTeamScore}?activityId=${activityId}&paperId=${paperId}&resultPaperId=${resultPaperId}`
    if (userId) {
      path = `${path}&userId=${userId}`
    } else if (groupId) {
      path = `${path}&groupId=${groupId}`
    }
    const record: any = await get({
      url: path,
    })
    return record
  },
)
// 获取倒计时
const getCountDwonTimeThunk = createAsyncThunk('get_user_countdown_time', async (opt: { resultPaperId: string }) => {
  const record = await get({
    url: `${getCountDownTime}?resultPaperId=${opt.resultPaperId}`,
  })
  return record
})

const guestSlice = createSlice({
  name: 'guest',
  initialState,
  reducers: {
    updateQsyCustomMsg: (state, actions) => {
      state.qsyCustomMessage = actions.payload
    },
  },
})
export const reducer = guestSlice.reducer
export const { updateQsyCustomMsg } = guestSlice.actions
export {
  postUserToTeamThunk,
  getApplyUserToTeamThunk,
  getUserWithGroupThunk,
  postSubmitRushThunk,
  getPaperQuestionListByGuestThunk,
  addUserBaseScoreThunk,
  getPersonTeamScoreThunk,
  getUserApplyWithGroupThunk,
  getCountDwonTimeThunk,
}
