import React, { ErrorInfo } from 'react'
import { log } from './utils/utils'

interface ErrorBoundaryProps {
  message?: React.ReactNode
  description?: React.ReactNode
}
export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { error: Error | null; info: ErrorInfo }
> {
  state = {
    error: null,
    info: {
      componentStack: '',
    },
  }

  handleClose() {
    window?.parent?.close()
    // @ts-ignore
    window?.qssdk?.interaction?.['close']()
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    //TODO: upload log to service
    this.setState({ error, info })
  }
  render(): React.ReactNode {
    const { children } = this.props
    const { error } = this.state
    log({ error: JSON.stringify(error) })
    return error ? (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'absolute',
          top: '40%',
        }}
      >
        {(error as Error).message == '请使用非无痕模式打开' ? (
          <>
            <div>请使用非无痕模式打开</div>
            <div>
              ，点击
              <span style={{ color: '#4F8BFF', cursor: 'pointer' }} onClick={this.handleClose}>
                关闭
              </span>
              页面
            </div>
          </>
        ) : (
          <>
            <div>网络异常，页面加载失败</div>
            <div>
              请
              <span
                style={{ color: '#4F8BFF', cursor: 'pointer' }}
                onClick={() => {
                  location.reload()
                }}
              >
                刷新
              </span>
              重试， 点击
              <span style={{ color: '#4F8BFF', cursor: 'pointer' }} onClick={this.handleClose}>
                关闭
              </span>
              页面
            </div>
          </>
        )}
      </div>
    ) : (
      children
    )
  }
}
