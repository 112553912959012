import { RoleItem } from './config.interface'

export const label_option_list = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'] as const

export const sdkTypeId = 10002
// 存储现在用户的paperid, activityid, questionid.key是p、a、q
// export const HostAswerPageSessionKey = 'qsy-rush-question-host-p'
export const sort_play_key = 'qsy-sort-play-k'
export const system_local_key = 'qsy-sys-local-k'
export const time_key = 'qsy_timer_'
export const is_show_activity_end = 'qsy_activity_end'
export const time_end_key = 'qsy_timer_end_' // 倒计时结束时间戳
export const H5UIDesign = { width: 750, height: 1624 }
export interface IqsyUserInfoObj {
  confRole: RoleItem // 当前角色
  btplConfRoles?: RoleItem // 当前角色
  umsUserID: number
  /** 用户ID */
  tempUserId: number
  //网易聊天室用户id
  accid: string
  /** 第三方ID,
用于对接第三方的列表
来源，比如直播间 */
  extId: string
  /** 用户姓名 */
  name: string
  email: string
  /**
用户手机号码，该号码
是用户属性的，非电话
语音使用的电话号码 */
  mobile: string
  /* 用户角色
1：主持人 2:
联席主持人 3: 嘉宾 4:
观众*/
  interactRole: number
  /**
电话语音使用的电话号
码 */
  phoneNum: string
  /**
 * 状态 0 ：下线用户
1：在线用户
 */
  status: number
  /**
参会人列表中用户所在
的分组*/
  groupName: string
  /** 头像*/
  imagePath: string
  /** 用户标签 */
  joinTag: string
}

export interface IqshMettingInfoObj {
  btplConfRoles?: RoleItem[] // 角色信息
  conferenceJoinUrl: string //会议链接
  roleList: string
  //角色列表
  confName: string
  //会议名称
  confHostName: string //主持人名称
  startTime: Date //会议开始时间
  endTime: Date //会议结束时间
  title: string
  //会议主题
  tempConferenceId: string // 临时会议id
  conferenceId: string
  // 会议id
  customerCode: string
  // 客户编号
}

export interface ILocalUserInfo {
  id: number
  name: string
  /**\
   * qsy的userid
   */
  qsyUUID: string
  /**
   * 用户头像
   */
  headImage: string
  /**
   * 临时tempuserid
   */
  tempuserid: string
  /**
   * accid
   */
  accid: string
  extId: string
  mobile: string
  phoneNum: string
  email: string
  groupId?: number
  isGroup: number
}
export class BaseError extends Error {
  constructor(message: string) {
    super(message)
    this.message = message
  }
}
// 交互message
export interface QsyMessageInfo {
  /**
   * 发送消息的uuid
   */
  uuid?: string
  /**
   * 是否是主持人发的
   */
  isHost?: boolean
  /**
   * 发送消息人员本地id
   */
  localId?: number
  /**
   * 0：申请入队，1 开始试题抢答，2 选手抢答试题,3 选手加入团队 4 返回的需要弹窗message的消息 5 主持端发送抢答人列表给全部人 6为加减分消息 7为加时消息处理 8主持端发送倒计时 9 发布结果 10 发布最佳选手
   * 11 活动结束 12 隐藏窗体 13同步一下用户的队伍信息 14 同步下试卷中的团队个人的分数 15 结束抢答 16 结束发言 17 选手发起结束发言 18 获取下主持下的倒计时时间 19 主持下发时间 20 主持和联席主持路由变更
   * 21 联席主持人和主持人中比赛排名和最佳选手tab互换
   * 22 联席主持人和主持人toast交互
   * 23 在用户退出后重新进入会的时候，发送消息到主持端，更新下
   * 24 联席主持人和主持人在入队申请后同步下申请队列
   * 25 离线进入的时候，全部端更新下主持人和联席主持人
   * 26 选手列表邀请发言时，更新下用户端按钮状态
   * 27 选手列表结束发言时，更细下用户状态
   */
  t:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24
    | 25
    | 26
    | 27
  /**
   * m:t 发送的文本信息，p: 发送的参数
   * 3,0的时候p: tid团队id，name加入人名称，icon人员icon, accid,extId,tempuserid
   * 1的时候p: pid试卷id,qid试题id,accid,extId,tempuserid rid: resultPaperId
   * 2，5，17的时候p:pid试卷id,qid试题id,name加入人名称，headImage人员icon, accid,extId,tempuserid,groupName,userId,id
   * 6: pid试卷id,qid试题id,name加入人名称，headImage人员icon, accid,extId,tempuserid,groupName, groupNumber, userId correct score
   * 7：pid试卷id,qid试题id,name加入人名称，headImage人员icon, accid,extId,tempuserid,groupName,userId time
   * 8： pid试卷id,qid试题id,name加入人名称，headImage人员icon, accid,extId,tempuserid,groupName,userId time
   * 9：pid,aid,rid,type
   * 10：pid,aid,rid,type
   *  13: groupId
   * 14：pid试卷id,qid试题id,name加入人名称，headImage人员icon, accid,extId,tempuserid,groupName, groupNumber, userId correct score
   * 15：16: userId
   * 18： 主要解决刷新页面后，倒计时丢失了
   * 19： 主持下发时间 accid,extId,tempuserid,name,userId,second
   */
  m: { t: string; p: any }
}
